import './App.css';
import React from 'react';
import {ReactComponent as Logo} from "./NeutrifyLogo.svg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div>
        <div class="PadBottom"><Logo></Logo></div>
      </div>
      <div id="Bar" class="Green ScreenStretch">
          <h3 id="Sub">Novel Health Technologies</h3>
        </div>
        <p id ="Desc">Innovating market-redefining products to support the gut-brain axis and to enhance human well-being.</p>
        <div class="Grey4 ScreenStretch Footer">
        <p class="Text-White Subtitle">©2023 Neutrify All Rights Reserved</p>
      </div>
      
      </header>
    </div>
  );
}

export default App;
